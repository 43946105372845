<template>
  <SitePopup
    :visible="props.modelValue"
    title="Se connecter"
    @close="emit('update:model-value', false)"
  >
    <SitePopupContent
      v-if="props.modelValue"
      :allow-desauthent="props.allowDesauthent"
      @authenticated="emit('authenticated')"
      @sign-up="signUp"
    />
  </SitePopup>
</template>

<script setup>
  import SitePopup from '@/components/SitePopup'

  const SitePopupContent = defineAsyncComponent(() => import('./Content'))

  const props = defineProps({
    modelValue: Boolean,
    allowDesauthent: Boolean
  })

  const emit = defineEmits(['update:model-value', 'signup', 'authenticated'])

  const signUp = () => {
    emit('update:model-value', false)
    setTimeout(() => {
      emit('signup')
    }, 600)
  }

</script>

<style lang="scss">
  .site-sign-up {
    
  }
</style>