<template>
  <Transition name="popup-fade">
    <div class="site-popup" v-if="visible">
      <div class="site-popup__header">
        <div class="site-popup__header__close" @click="close">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.1036 0.220691C0.536755 0.526001 0.11162 1.11482 0.0462149 1.69272C-0.0845958 2.83764 -0.26991 2.60865 4.62459 7.50452L9.11575 12.0078L4.62459 16.5003C-0.26991 21.407 -0.0845958 21.178 0.0462149 22.323C0.11162 22.9009 0.536755 23.4897 1.1036 23.795C1.51783 24.024 2.3354 24.0676 2.81504 23.8931C2.97855 23.8277 5.11513 21.7778 7.56783 19.3353L12.0045 14.8974L16.452 19.3353C18.8938 21.7778 21.0304 23.8277 21.1939 23.8931C21.9243 24.1548 22.9926 23.9149 23.4613 23.3915C23.9628 22.8136 24.1372 21.8977 23.8865 21.1999C23.821 21.0363 21.7717 18.8991 19.3299 16.4457L14.8932 12.0078L19.3299 7.55904C21.7717 5.11656 23.821 2.97939 23.8865 2.81583C24.0609 2.33605 24.0173 1.51826 23.7883 1.10391C23.4831 0.536904 22.8945 0.111652 22.3167 0.0462279C21.1721 -0.0846193 21.4011 -0.269983 16.4957 4.62588L12.0045 9.1183L7.55693 4.6804C5.11513 2.23792 2.97855 0.18798 2.81504 0.122556C2.3354 -0.0519067 1.51783 -0.00829139 1.1036 0.220691Z" fill="white"/>
          </svg>
        </div>
        <div class="site-popup__header__logo">
          <SiteLogo
            secondary="white"
            class="site-popup__header__logo__img"
          />
        </div>
        <div v-if="props.surtitle" class="site-popup__header__surtitle">
          {{ props.surtitle }}
        </div>
        <div class="site-popup__header__title">
          {{ props.title }}
        </div>
        <div v-if="props.subtitle" class="site-popup__header__subtitle">
          {{ props.subtitle }}
        </div>
      </div>
      <div class="site-popup__body">
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { useOverlay } from '@/components/SiteOverlay/useOverlay'
import SiteLogo from '@/components/SiteLogo'
import { watch } from 'vue'

const props = defineProps({
  surtitle: String,
  title: String,
  subtitle: String,
  visible: Boolean
})

const overlay = useOverlay()

const close = () => {
  emit('close')
}

watch(() => props.visible, () => {
  if (props.visible) {
    overlay.set(true)
    overlay.subscribeClose(close)
  } else {
    overlay.set(false)
  }
})

const emit = defineEmits(['close'])

</script>

<style lang="scss">
  $desktop-breakpoint: 990px;
  $tablet-breakpoint: 672px;

  .popup-fade-enter-active {
    transition: opacity 0.45s ease-out;
  }
  .popup-fade-leave-active {
    transition: opacity 0.45s ease-in;
    transition-delay: 0.15s;
  }

  .popup-fade-enter-from,
  .popup-fade-leave-to {
    opacity: 0;
  }
  .site-popup {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    overflow: none;
    background-color: none;
    max-height: 95vh;

    &__body {
      overflow-y: auto;
      background-color: white;
      padding: 20px;
      @media screen and (min-width: $tablet-breakpoint) {
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }

    @media screen and (min-width: $tablet-breakpoint) {
      bottom: initial;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 600px;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      width: 800px;
    }

    &__header {
      background-color: #01498B;
      position: relative;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      padding-top: 50px;
      padding-bottom: 30px;

      @media screen and (min-width: $tablet-breakpoint) {
        
        padding-bottom: 40px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        
        padding-bottom: 50px;
      }

      &__logo {
        width: 60px;
        top: -50px;
        margin-left: 50%;
        transform: translateX(-50%);
        position: absolute;

        &__img {
          width: 60px;
          height: 88.67px;
        }

        @media screen and (min-width: $tablet-breakpoint) {
          width: 70px;
          top: -60px;

          &__img {
            width: 70px;
            height: 103.44px;
          }
        }
      }

      &__close {
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
      }
      &__surtitle {
        color: white;
        text-align: center;
        font-weight: 200;
        font-size: 24px;
        @media screen and (min-width: $tablet-breakpoint) {
          font-size: 26px;
        }

        @media screen and (min-width: $desktop-breakpoint) {
          font-size: 28px;
        }
      }
      &__title {
        color: white;
        font-size: 40px;
        text-align: center;
        font-weight: 500;

        @media screen and (min-width: $desktop-breakpoint) {
          font-size: 60px;
          font-weight: 600;
        }
      }
      &__subtitle {
        margin-top: 10px;
        color: white;
        font-size: 18px;
        text-align: center;
        font-weight: 100;

        @media screen and (min-width: $desktop-breakpoint) {
          font-weight: 200;
        }
      }
    }
  }
</style>