<template>
  <SitePopup
    :visible="!!props.modelValue"
    surtitle="Ouvrir un"
    title="Compte"
    subtitle="Gratuit et illimité dans le temps"
    @close="emit('update:model-value', false)"
  >
    <SitePopupContent
      v-if="props.modelValue"
      :from="props.modelValue"
      :allow-desauthent="props.allowDesauthent"
      :firstname="props.firstname"
      :lastname="props.lastname"
      @login="loginWith"
      @authenticated="emit('authenticated')"
      @close="emit('update:model-value', false)"
    />
  </SitePopup>
</template>

<script setup>
  import SitePopup from '@/components/SitePopup'
 
  const SitePopupContent = defineAsyncComponent(() => import('./Content'))
  
  const props = defineProps({
    modelValue: Boolean | String,
    firstname: String | null | undefined,
    lastname: String | null | undefined,
    allowDesauthent: Boolean
  })

  const emit = defineEmits(['update:model-value', 'login', 'authenticated'])

  const loginWith = () => {
    emit('update:model-value', false)
    setTimeout(() => {
      emit('login')
    }, 600)
  }
</script>

<style lang="scss">
  .site-sign-up {
    
  }
</style>